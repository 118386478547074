import React from "react"

import Layout from "components/Layout/en"
import Gallery from "components/Gallery/Gallery"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

export default function(props) {
  const header = {
    text: "MoonVision Career",
    description:
      "Join our mission of developing a better future, one in which AI is used to better understand and improve the world around us.",
  }
  return (
    <Layout header={header} location={props.location}>
      <div className="grey-background">
        <div className="vivid_teaser p-5">
          <Container>
            <p>Career</p>
            <h1>Work @ MoonVision</h1>
            <p>
              Join our mission of developing a better future, one in which AI is
              used to better understand and improve the world around us.
            </p>
          </Container>
        </div>
        <div className="container py-4">
          <h2>Open Positions</h2>
        </div>
        <div className="white-background">
          <Container>
            <Row className="py-5">
              <Col className="job my-2" md="12" lg="4">
                <div className="card h-100">
                  <div className="card-body">
                        <h2 className="card-title">Frontend Developer</h2>
                            <p className="card-text">
                            Here, we need your expertise and creativity to improve the frontend side of our app.moonvision.io platform. You will work closely with our Backend team as well our CV Engineers to develop new features and improve the design of the platform. Therefore, strong affinity for UI/UX is also desired.
                            </p>
                            <br />
                            <Link to="/career/frontend-dev-role">
                              <Button className="btn btn-primary float-right">
                                Find out more
                              </Button>
                            </Link>
                          </div>
                      </div>
             </Col>
            </Row>
          </Container>
        </div>
        <div className="container py-4">
          <h2>Office Life @ MoonVision</h2>
        </div>
        <div className="pb-4">
          <Gallery />
        </div>
      </div>
    </Layout>
  )
}
